<template>
  <section>
    <breadcrumb :title="$t('users')" :subtitle="$t('list_user')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="filter=!filter" class="ml-25 cursor-pointer">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
      </div>
      <template v-if="filter">
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('type')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('agent')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('agent')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('dealer')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('email')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('user')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-flex-end">
              <b-button class="btn btn-search" type="is-primary">
                {{ $t('search') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts"
          ref="table"
          :paginated="false"
          per-page="per_page"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="type" :label="$t('type')" v-slot="props">
          <b-tag v-if="props.row.rol === rolesName.DEALER" type="is-tag-success success" rounded>
            <span class="text-status admin is-uppercase"> {{ $t('dealer') }}</span>
          </b-tag>
          <span v-else class="tag purple rounded-10 width-170">
            <span class="text-status dealer is-uppercase"> {{ $t('super_admin') }}</span>
          </span>
        </b-table-column>
        <b-table-column field="username" :label="$t('username')" v-slot="props">
        <span class="col username">
          {{ props.row.username }}
        </span>
        </b-table-column>
        <b-table-column field="agent_agency_dealer" :label="$t('agent_agency_dealer')" v-slot="props">
        <span class="col agent_agency_dealer is-uppercase">
          {{ props.row.agent_agency_dealer }}
        </span>
        </b-table-column>
        <b-table-column field="email" :label="$t('email')" v-slot="props">
        <span class="col email">
          {{ props.row.email }}
        </span>
        </b-table-column>

        <b-table-column field="image" :label="$t('image')" v-slot="props">
          <avatar v-if="props.row.avatar" :src="props.row.avatar"/>
          <avatar :size="40" v-else :username="props.row.username"/>
        </b-table-column>

        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div>
              <b-icon type="is-primary" icon="launch" custom-class="mdi-rotate-90 cursor-pointer" class="mx-4"></b-icon>
            </div>
            <div v-if="props.row.type!== rolesName.SUPER_ADMIN" @click="setPropsModals(props.row)">
              <b-icon type="is-green-accent" icon="pencil"
                      class="mx-4 cursor-pointer"></b-icon>
            </div>
            <div v-if="props.row.type!== rolesName.SUPER_ADMIN">
              <b-icon type="is-pink-light" icon="close-circle-outline cursor-pointer" class="mx-4"></b-icon>
            </div>
          </div>
        </b-table-column>
      </b-table>
      <paginate :count="desserts.length" :per_page="per_page" :current-page="1"/>
      <b-modal
          v-model="isModalEditUserActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modals-edit-user :item="propsModals" v-bind="propsModals"
                            @close="props.close"></modals-edit-user>
        </template>
      </b-modal>
    </section>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {ROLE_NAMES} from "@/enum/roleNameUser";
import Avatar from "vue-avatar";
import ModalsEditUser from "@/components/modals/modalsEditUser";

export default {
  name: "listUserComponent",
  components: {ModalsEditUser, Paginate, Breadcrumb, Avatar},
  data() {
    return {
      filter_name: 'filter-outline',
      filter: false,
      rolesName: ROLE_NAMES,
      isModalEditUserActive: false,
      propsModals: {},
      per_page: 5,
      desserts: [
        {
          rol: 1,
          username: 'Admin',
          email: 'admin@telluspros.com',
          agent_agency_dealer: '',
          avatar: null,
        },
        {
          rol: 2,
          username: 'Ericel',
          email: 'ericel1970@gmail.com',
          agent_agency_dealer: 'ERICEL VALDES PEREZ-52408631',
          avatar: null,
        },
      ]
    }
  },
  methods: {
    setPropsModals(newProp) {
      this.propsModals = newProp
      this.isModalEditUserActive = true
    },
  },
  watch: {
    filter: function () {
      this.filter_name = this.filter ? 'filter-remove-outline' : 'filter-outline'
    }
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.username {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

  &.email, &.agent_agency_dealer {
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #0B2B46;
  }


}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.admin {
    color: #289BA4;
  }

  &.dealer {
    color: #4D5EC3;
  }

}

.purple {
  background: #dbdffa;
}

.tag {
  width: 176px !important;
}

</style>