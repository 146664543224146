<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('edit_user') }}</div>
          <button @click="$emit('close')" class="delete" aria-label="close"></button>
        </div>
        <section class="px-20">
          <div class="is-flex ml-60 mt-20 ">
            <b-tag type="is-green-success" class="width-110" rounded>{{ $t('credentials') }}</b-tag>
          </div>
          <div class="columns is-align-items-center is-flex mt-10">
            <div class="column is-4 is-align-items-center is-flex-direction-column">
              <div class="rounded-50x avatar">
                <span class="mdi mdi-account-circle account-avatar"></span>
              </div>
              <avatar/>
            </div>
            <div v-if="item.avatar" class="column is-half is-flex is-flex-direction-column">
              <span class="help">{{ $t('avatar') }}</span>
              <span class="help">{{ $t('avatar_help') }}</span>
            </div>
            <div v-else class="column is-half is-flex is-flex-direction-column">
              <user-data :item="item"/>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <b-field :label="`${$t('rol')}*`" custom-class="custom-field-label">
                <b-select expanded>
                  <option></option>
                </b-select>
              </b-field>
              <b-field :label="`${$t('email')}*`" custom-class="custom-field-label">
                <b-input type="text" placeholder="" custom-class="full-input"></b-input>
              </b-field>
              <b-field :label="`${$t('username')}*`" custom-class="custom-field-label">
                <b-input type="text" placeholder="" custom-class="full-input"></b-input>
              </b-field>
              <b-field :label="`${$t('password')}*`" custom-class="custom-field-label">
                <b-input type="text" placeholder="" custom-class="full-input"></b-input>
              </b-field>
              <b-field :label="`${$t('valid_password')}*`" custom-class="custom-field-label">
                <b-input type="text" placeholder="" custom-class="full-input"></b-input>
              </b-field>
            </div>
          </div>
          <div class="buttons mt-40 mb-20 is-flex is-align-items-center is-justify-content-flex-end">
            <button class="button save is-primary width-270 rounded-10">Guardar</button>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import UserData from "@/components/structure/userData";

export default {
  name: "modalsEditUser",
  components: {UserData},
  props: ['item'],

}
</script>

<style scoped lang="scss">
.avatar {
  height: 159px;
  width: 159px;
  background: #DEDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help {
  &.data {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #0B2B46;

  }
}

.icon {
  margin-right: 0 !important;
}

.account-avatar {
  font-size: 100px;
}

.custom-field-label {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 32px !important;
  letter-spacing: 0.5px !important;
  color: #AEAEAE !important;
}
</style>