<template>
  <section>
    <div class="help data is-flex is-align-items-center">
      <b-icon type="is-dark" icon="account-circle"
              class="mx-4 cursor-pointer"></b-icon>
      <span class="ml-10">{{ item.username }}</span></div>
    <div class="help data is-flex is-align-items-center">
      <b-icon type="is-dark" icon="email-outline"
              class="mx-4 cursor-pointer"></b-icon>
      <span class="ml-10">{{ item.email }}</span></div>
    <div class="is-flex is-align-items-center">
      <b-icon type="is-dark" icon="account-multiple-outline"
              class="mx-4 cursor-pointer"></b-icon>
      <b-tag type="is-green-success" class="min-width-110 px-10 ml-10" rounded>{{ $t(getRoleName(item.rol)) }}</b-tag>
    </div>
  </section>
</template>

<script>
import {ROLE_NAMES} from "@/enum/roleNameUser";

export default {
  name: "userData",
  props: ['item'],
  methods: {
    getRoleName(id) {
      switch (id) {
        case ROLE_NAMES.SUPER_ADMIN:
          return 'super_admin';
        case ROLE_NAMES.DEALER:
          return 'dealer';
        case ROLE_NAMES.AGENCY:
          return 'agency';
        case ROLE_NAMES.AGENT:
          return 'agent'
      }
    }
  }
}
</script>

<style scoped>

</style>